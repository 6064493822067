import { ModelsStore } from "../types";
import { Section } from "@models/project";
import { cloneDeep, defaultsDeep } from "lodash";
import { SetState } from "zustand";

export const sectionsSlice = (set: SetState<ModelsStore>) => ({
  sections: {},
  sectionsCount: 1,
  setSections: (modelId: string, sections_: Section[]) => {
    set(({ sections }) => {
      return {
        sections: {
          ...sections,
          [modelId]: {
            sections: sections_,
          },
        },
      };
    });
  },
  updateSection: (modelId: string, idx: number, update: Partial<Section>) => {
    let result: Section | undefined;
    set(({ sections }: ModelsStore) => {
      const modelSections = sections[modelId].sections;
      const changedSections = [...modelSections];
      const mergedUpdate = { ...update };
      if ("attributes" in update) {
        const copy: Section["attributes"] = cloneDeep(update["attributes"]) as Section["attributes"];
        defaultsDeep(copy, changedSections[idx]["attributes"]);
        mergedUpdate["attributes"] = copy;
      }

      const section = {
        ...modelSections[idx],
        ...mergedUpdate,
      };

      changedSections[idx] = section;

      result = section;
      return {
        sections: {
          ...sections,
          [modelId]: {
            sections: changedSections,
          },
        },
      };
    });
    return result!;
  },
});
