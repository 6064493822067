import { WorkModel } from "@models/project";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader.js";
import { GLTFLoader, GLTF } from "three/examples/jsm/loaders/GLTFLoader.js";
import { GLTFExporter } from "three/addons/exporters/GLTFExporter.js";
import {
  BufferAttribute,
  BufferGeometry,
  DoubleSide,
  Group,
  Mesh,
  MeshStandardMaterial,
  Object3D,
  Object3DEventMap,
  Scene,
} from "three";
import save from "file-saver";
import { Mesh3DBase } from "@variant-tech/pattern-derivation";

function arrayBufferToBlob(buffer: ArrayBuffer) {
  return new Blob([buffer], { type: "application/octet-stream" });
}

function stringToBlob(text: string) {
  return new Blob([text], { type: "text/plain" });
}

function getLoader(ext: string) {
  switch (ext) {
    case "obj":
      return new OBJLoader();
    case "stl":
      return new STLLoader();
    case "gltf":
      return new GLTFLoader();
    case "glb":
      return new GLTFLoader();
    default:
      throw new Error(`Unsupported file extension: ${ext}`);
  }
}

export async function getGeoPositionsArray(url: string, ext: string) {
  const loader = getLoader(ext);

  const group = new Group();

  const object = await loader
    .loadAsync(url)
    .then((obj) => {
      return obj;
    })
    .catch((err) => {
      console.log("error loading model:", { err, url });
      return null;
    });

  // if model is an STL, we can return the geometry directly
  if (ext === "stl") {
    return (object as BufferGeometry).attributes.position.array;
  }

  // whereas for GLTF/GLB and OBJ we need to add it to a Group which we can then traverse
  if (ext === "gltf" || ext === "glb") {
    group.add((object as GLTF).scene);
  } // ... OBJ
  else {
    group.add(object as Object3D);
  }

  let positionsArray: ArrayLike<number> = [];

  // and then return the geometry from the first mesh we find as BufferGeometry
  group.traverse((child: Object3D<Object3DEventMap>) => {
    if ((child as unknown) instanceof Mesh) {
      let childGeometry = (child as Mesh).geometry as BufferGeometry;
      if (childGeometry.index) {
        childGeometry = childGeometry.toNonIndexed();
      }
      positionsArray = childGeometry.attributes.position.array;
    }
  });
  return positionsArray;
}

export async function getMeshFromMesh3DBase(mesh3DBase: Mesh3DBase) {
  const vertices = await mesh3DBase.verticesAsFloat32Buffer();
  const faceIndices = Array.from(await mesh3DBase.facesAsInt32Buffer());
  const geometry = new BufferGeometry();
  geometry.setIndex(faceIndices);
  geometry.setAttribute("position", new BufferAttribute(vertices, 3));
  geometry.computeVertexNormals();

  const mat = new MeshStandardMaterial({
    color: "lightgray",
    // wireframe: true,
  });

  const mesh = new Mesh(geometry, mat);
  return mesh;
}

export async function exportMeshToGLB(mesh: Mesh, name = "scene", binary = true, debug = false): Promise<File> {
  const exportScene = new Scene().add(mesh);

  const gltfExporter = new GLTFExporter();

  const options = {
    trs: false,
    onlyVisible: true,
    binary,
    maxTextureSize: 4096,
  };

  return new Promise<File>((resolve, reject) => {
    gltfExporter.parse(
      exportScene,
      function (result) {
        let blob;
        let filename;
        if (result instanceof ArrayBuffer) {
          blob = arrayBufferToBlob(result);
          filename = `${name}.glb`;
        } else {
          const output = JSON.stringify(result, null, 2);
          blob = stringToBlob(output);
          filename = `${name}.gltf`;
        }
        const file = new File([blob], filename, { type: blob.type });
        if (debug) {
          save(file, filename);
        }
        resolve(file);
      },
      reject,
      options,
    );
  });
}

export async function decimate(model: WorkModel, numVertices = 3000) {
  const newMesh = await model.mesh3DBase.pmpDecimate(numVertices);
  const treeMesh = await getMeshFromMesh3DBase(newMesh);
  return exportMeshToGLB(treeMesh);
}

export async function initMesh(bufferedPositions: ArrayLike<number>) {
  if (!v3dApi) {
    throw new Error("v3dApi is not yet loaded");
  }
  const mesh3DBase = await v3dApi.createMesh3DBaseFromBufferedPositions(bufferedPositions as Float32Array, 3); //.catch(err => console.log(err));
  console.log(`Loaded mesh with ${await mesh3DBase.nVertices()} vertices and ${await mesh3DBase.nFaces()} faces`);

  // create a mesh from the mesh3d object
  const vertices = await mesh3DBase.verticesAsFloat32Buffer();
  const faceIndices = Array.from(await mesh3DBase.facesAsInt32Buffer());
  const geometry = new BufferGeometry();
  geometry.setIndex(faceIndices);
  geometry.setAttribute("position", new BufferAttribute(vertices, 3));
  geometry.computeVertexNormals();

  const mat = new MeshStandardMaterial({
    color: "lightgray",
    side: DoubleSide,
  });

  const mesh = new Mesh(geometry, mat);
  return { mesh3DBase, mesh };
}

export async function getMeshPayload(path: string, fileName: string) {
  if (!v3dApi) {
    throw new Error("v3dApi is not yet loaded");
  }

  const ext = fileName.split(".").pop() as string;

  const bufferedPositions = await getGeoPositionsArray(path, ext);
  const { mesh3DBase, mesh } = await initMesh(bufferedPositions);

  return { bufferedPositions, mesh, mesh3DBase };
}

export async function scaleMesh({ mesh3DBase }: WorkModel, scale: number) {
  await mesh3DBase.setScale(scale, scale, scale);

  const vertices = await mesh3DBase.verticesAsFloat32Buffer();
  const faceIndices = Array.from(await mesh3DBase.facesAsInt32Buffer());
  const geometry = new BufferGeometry();
  geometry.setIndex(faceIndices);
  geometry.setAttribute("position", new BufferAttribute(vertices, 3));
  geometry.computeVertexNormals();

  const mat = new MeshStandardMaterial({
    color: "lightgray",
    side: DoubleSide,
  });

  const mesh = new Mesh(geometry, mat);
  return { mesh, mesh3DBase };
}
