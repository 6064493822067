import BackendApi from "@api/BackendApi.ts";
import { AuthContext } from "@auth/AuthContext";
import { useInitAuth } from "@auth/useInitAuth.ts";
import { Button, Center, HStack, Text, VStack } from "@chakra-ui/react";
import { Loading } from "@fragments";
import { useTranslation, ViewVersionProvider } from "@hooks";
import { Onboarding } from "@routes";
import { wrapCreateBrowserRouter } from "@sentry/react";
import initV3dApi from "@utils/project/initV3dApi";
import { lazy, Suspense } from "react";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";

export const Home = lazy(() => import("./routes/Home"));
export const Project = lazy(() => import("./routes/Project"));
export const Playground = lazy(() => import("./routes/Playground"));

const basename = "/app/";

export default function App() {
  const { accessToken, isLoading, error, headers, loginWithRedirect, logout, refreshAuthHeaders, user } = useInitAuth();
  const { t } = useTranslation();

  window.refreshAuthHeaders = refreshAuthHeaders;

  if (error) {
    return (
      <Center flex="1 1 auto" h="full">
        <VStack>
          <Text
            textAlign="center"
            dangerouslySetInnerHTML={{ __html: t(`authentication.errors.${error.message}`, {}, error.message) }}
          />
          <HStack>
            <Button onClick={() => window.location.replace(basename)} variant="outline">
              {t("global.retry")}
            </Button>
            {accessToken && <Button onClick={logout}>{t("applicationContextMenu.logout")}</Button>}
          </HStack>
        </VStack>
      </Center>
    );
  }

  if (isLoading) {
    return <Loading height="full" />;
  }

  if (!accessToken || !headers || !user) {
    return <Onboarding loginWithRedirect={loginWithRedirect} />;
  }

  if (!window.location.pathname.startsWith(basename)) {
    window.location.replace(basename + window.location.pathname.substring(1));
  }

  const router = wrapCreateBrowserRouter(createBrowserRouter)(
    [
      {
        index: true,
        element: <Navigate to="/projects" />,
      },
      {
        path: "/*",
        element: (
          <Suspense fallback={<Loading />}>
            <Home />
          </Suspense>
        ),
      },
      {
        path: "projects/:id",
        element: (
          <Suspense fallback={<Loading />}>
            <Project />
          </Suspense>
        ),
        loader: async ({ params: { id } }: { params: { id: string } }) => {
          const project = await BackendApi.getProject({ headers, params: { id } });
          const v3dApi = await initV3dApi();
          return {
            project,
            v3dApi,
          };
        },
      },
      {
        path: "/playground",
        element: (
          <Suspense fallback={<Loading />}>
            <Playground />
          </Suspense>
        ),
      },
    ],
    { basename },
  );

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        headers,
        user,
        logout,
      }}
    >
      <ViewVersionProvider>
        <RouterProvider router={router} />
      </ViewVersionProvider>
    </AuthContext.Provider>
  );
}
