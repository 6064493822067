import { createContext, useState, useContext, PropsWithChildren } from "react";
import pen from "/cursors/pen.png";
import penCoincident from "/cursors/penCoincident.png";
import penMinus from "/cursors/penMinus.png";
import select from "/cursors/select.png";
import selectMinus from "/cursors/selectMinus.png";
import selectBending from "/cursors/selectBending.png";

const cursorMap = {
  pen: `url(${pen}), auto`,
  coincident: `url(${penCoincident}), auto`,
  remove: `url(${penMinus}), auto`,
  select: `url(${select}), auto`,
  selectMinus: `url(${selectMinus}), auto`,
  selectBending: `url(${selectBending}), auto`,
};

export type CursorMapKeys = keyof typeof cursorMap;

type CursorContextProps = {
  cursor: string;
  setCursor: (cursor: CursorMapKeys) => void;
};

const CursorContext = createContext<CursorContextProps>({
  cursor: cursorMap["select"],
  setCursor: () => {},
});

export function CursorProvider({ children }: PropsWithChildren) {
  const [cursor, setCursor] = useState<CursorContextProps["cursor"]>(cursorMap["select"]);

  return (
    <CursorContext.Provider
      value={{
        cursor,
        setCursor: (cursor) => setCursor(cursorMap[cursor]),
      }}
    >
      {children}
    </CursorContext.Provider>
  );
}

export const CursorConsumer = CursorContext.Consumer;

export const useCustomCursor = () => useContext(CursorContext);
